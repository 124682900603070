import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTES } from '../../routes/AppRoutes';
import TopBar from "./TopBar";
import NavBar from "./NavBar";
import "./header.css";

export default function Header() {
  const { t } = useTranslation();
  const { lng } = useParams();
  
  const routes = ROUTES(t, lng); 
  
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <TopBar />
      <NavBar routes={routes} menuOpen={menuOpen} toggleMenu={toggleMenu} />
    </header>
  );
}
