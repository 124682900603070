import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";

export default function Navbar({ routes, menuOpen, toggleMenu }) {
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isClosing, setIsClosing] = useState(false);

  const toggleSubMenu = (index) => {
    if (activeSubMenu === index) {
      setActiveSubMenu(null);
    } else {
      setActiveSubMenu(index);
    }
  };

  const handleMenuClick = () => {
    if (menuOpen) {
      setIsClosing(true);
      setTimeout(() => {
        setIsClosing(false);
        toggleMenu();
      }, 500);
    } else {
      toggleMenu();
    }
  };

  return (
    <nav className="navbar">
      <div className="header-container">
        <div className="navbar-logo">DHI</div>
        <button className="menu-toggle" onClick={handleMenuClick}>
          ☰
        </button>
        <ul
          className={`navbar-menu ${menuOpen ? "active" : ""} ${
            isClosing ? "closing" : ""
          }`}
        >
          <li>
            <Link to={routes.home.path} onClick={handleMenuClick}>
              {routes.home.title}
            </Link>
          </li>
          <li
            className={`menu-item ${
              activeSubMenu === 1 || menuOpen ? "submenu-visible" : ""
            }`}
          >
            <Link onClick={() => toggleSubMenu(1)}>{routes.about.title}</Link>
            <ul className="submenu">
              <li>
                <Link to={routes.committees.path} onClick={handleMenuClick}>
                  {routes.committees.title}
                </Link>
              </li>
              <li>
                <Link
                  to={routes.deafHistoryInternational.path}
                  onClick={handleMenuClick}
                >
                  {routes.deafHistoryInternational.title}
                </Link>
              </li>
            </ul>
          </li>
          <li
            className={`menu-item ${
              activeSubMenu === 2 || menuOpen ? "submenu-visible" : ""
            }`}
          >
            <Link onClick={() => toggleSubMenu(2)}>
              {routes.programAndSpeakers.title}
            </Link>
            <ul className="submenu">
              <li>
                <Link
                  to={routes.programAndSpeakers.path}
                  onClick={handleMenuClick}
                >
                  {routes.programAndSpeakers.title}
                </Link>
              </li>
              <li>
                <Link to={routes.registration.path} onClick={handleMenuClick}>
                  {routes.registration.title}
                </Link>
              </li>
              <li>
                <Link to={routes.location.path} onClick={handleMenuClick}>
                  {routes.location.title}
                </Link>
              </li>
            </ul>
          </li>
          {/* <li>
            <Link to={routes.gallery.path} onClick={handleMenuClick}>
              {routes.gallery.title}
            </Link>
          </li> */}
          <li>
            <Link to={routes.contact.path} onClick={handleMenuClick}>
              {routes.contact.title}
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
