import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Flag from 'react-flagkit';
import "./languageselector.css";

export default function LanguageSelector() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { lng } = useParams();
  const location = useLocation();

  const changeLanguage = (newLng) => {
    if (lng !== newLng) {
      // Vervang de oude taal in de huidige URL met de nieuwe taal
      const newPathname = location.pathname.replace(`/${lng}`, `/${newLng}`);
      i18n.changeLanguage(newLng);
      navigate(newPathname); // Blijf op dezelfde pagina, maar met de nieuwe taal
    }
  };

  return (
    <nav aria-label="Language selector" className="language-selector">
      <button
        onClick={() => changeLanguage("en")}
        className={`language-option ${lng === "en" ? "active" : ""}`}
        aria-label="Switch to English"
      >
        <Flag country="GB" className="flag-icon" />
        <span className="language-code">EN</span>
      </button>
      <button
        onClick={() => changeLanguage("nl")}
        className={`language-option ${lng === "nl" ? "active" : ""}`}
        aria-label="Switch to Dutch"
      >
        <Flag country="NL" className="flag-icon" />
        <span className="language-code">NL</span>
      </button>
      <button
        onClick={() => changeLanguage("fr")}
        className={`language-option ${lng === "fr" ? "active" : ""}`}
        aria-label="Switch to French"
      >
        <Flag country="FR" className="flag-icon" />
        <span className="language-code">FR</span>
      </button>
    </nav>
  );
}
