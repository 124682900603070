import React from "react";
import "./footerlinks.css";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function FooterLinks() {
  const { t } = useTranslation();
  const { lng } = useParams();
  return (
    <div className="footer-links">
      <div className="links">
        <Link to={`/${lng}/privacy-policy`}>{t("navigation.privacy-policy")}</Link>
        <Link to={`/${lng}/terms-and-conditions`}>{t("navigation.terms-and-conditions")}</Link>
        <Link to={`/${lng}/cookies-policy`}>{t("navigation.cookies-policy")}</Link>
        <Link to={`/${lng}/contact`}>{t("navigation.contact")}</Link>
      </div>
      <div className="built-by">{t("footer.website-built")}</div>
    </div>
  );
}