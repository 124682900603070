import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next) // Hierdoor wordt `init()` automatisch uitgevoerd
  .init({
    supportedLngs: ["en", "nl", "fr"],
    fallbackLng: "en",
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: process.env.PUBLIC_URL + "/locales/{{lng}}/{{ns}}.json",
    },
    ns: [
      "common",
      "home",
      "about",
      "contact",
      "committees",
      "cookiesPolicy",
      "deafHistoryInternational",
      "gallery",
      "info",
      "location",
      "organizers",
      "privacyPolicy",
      "registrations",
      "termsAndConditions",
      "programAndSpeakers",
    ], // Voeg hier meer namespaces toe indien nodig
    defaultNS: "common",
    react: {
      useSuspense: false,
    },
  });

export default i18n;
