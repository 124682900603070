import React from "react";
import ContactInfo from "./ContactInfo";
import QuickLinks from "./QuickLinks";
// import Subscribe from "./Subscribe"; TODO: wachten op implementatie van mailchimp
import FooterLinks from "./FooterLinks";
import AboutUsFooter from "./AboutUsFooter";
import "./footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-columns">
          <div className="footer-column">
            <AboutUsFooter />
          </div>
          <div className="footer-column">
            <ContactInfo />
          </div>
          <div className="footer-column">
            <QuickLinks />
          </div>
        </div>
        <div className="disclaimers">
          {/* <Subscribe /> TODO: wachten op implementatie van mailchimp*/}
          <FooterLinks />
        </div>
      </div>
    </footer>
  );
}
