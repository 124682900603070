import React from "react";
import "./quicklinks.css";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

export default function QuickLinks() {
  const { t } = useTranslation();
  const { lng } = useParams();
  return (
    <div className="quick-links">
      <h6 className="footer-heading">
        {t("footer.quick-links")}
        <div className="footer-heading-underline"></div>
      </h6>
      <div className="links">
        <Link to={`/${lng}/home`}>{t("navigation.home")}</Link>

        <div className="spacer"></div>
        <Link to={`/${lng}/program-and-speakers`}>
          {t("navigation.program-and-speakers")}
        </Link>
        <div className="spacer"></div>
        <Link to={`/${lng}/deaf-history-international`}>
          {t("navigation.deaf-history-international")}
        </Link>
        <div className="spacer"></div>
        <Link to={`/${lng}/registration`}>{t("navigation.registration")}</Link>
        <div className="spacer"></div>
        <Link to={`/${lng}/contact`}>{t("navigation.contact")}</Link>
      </div>
    </div>
  );
}
