import React from "react";
import "./aboutusfooter.css";
import SocialLinks from "../SocialLinks";
import { useTranslation } from "react-i18next";

export default function AboutUsFooter() {
  const { t } = useTranslation(); // Haal de vertalingen op
  return (
    <div className="about-us-footer">
      <h6 className="footer-heading">
        {t("footer.about-us")}
        <div className="footer-heading-underline"></div>
      </h6>
      <p className="footer-text">
{t("footer.about-dhi")}
      </p>
      <SocialLinks />
    </div>
  );
}
