import React from "react";
import SocialLinks from "../SocialLinks";
// import Button from "../Button";
import LanguageSelector from "../../language/LanguageSelector";
import "./topbar.css";

export default function TopBar() {
  return (
    <div className="topbar">
      <div className="header-container">
        <LanguageSelector />
        <div className="topbar-right">
          <SocialLinks />
          {/* <Button labelKey="donate" /> TODO: opvolgen wanneer knop wenselijk is*/}
        </div>
      </div>
    </div>
  );
}
