import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import "./sociallinks.css";

export default function SocialLinks() {
  return (
    <div className="social-links">
      <a href="https://www.facebook.com/profile.php?id=61565804713510" target="_blank" rel="noopener noreferrer">
        <FacebookIcon className="social-icon" />
      </a>
      <a href="https://www.instagram.com/deafhistoryinternational2025?igsh=bGhzbXNwbGJwOXRk" target="_blank" rel="noopener noreferrer">
        <InstagramIcon className="social-icon" />
      </a>
    </div>
  );
}
